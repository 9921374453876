import React, { useEffect, useState } from 'react';
import styles from './ProductCard.module.scss';
import { useTranslation } from 'react-i18next';
import { ProductImage } from './models/product-image.model';
import { Product } from '../../components/products-page/models';
import { addToCart } from '../../components/cart/services/cart.service';
import { useNavigate } from 'react-router-dom';
import { calculateBrutto, decodeImageService } from '../services';
import { getProductImagesService } from '../services';
import CheckIcon from '@mui/icons-material/Check';
import { usePluginStore } from 'react-pluggable';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { PickupPointList } from '../pickup-points-list/PickupPointList';
import { PickupPoint } from '../map/models';
import { getDimensionedImage } from '../services/get-attachments.service';
import { PRODUCT_CARD_WIDTH, PRODUCT_CARD_HEIGHT, PRODUCER_CARD_HEIGHT } from '../constants/images-resolution.constants';
const cartIcon = require("../../assets/icons/cart.svg").default;

export default function ProductCard(props: any) {
    const pluginStore: any = usePluginStore();
    let Renderer = pluginStore.executeFunction("Renderer.getRendererComponent");
    const [hasBeenAddToCartClicked, setHasBeenAddToCartClicked] = useState<boolean>(false);

    const placeInCart = (product: Product, quantity: number, image: ProductImage, buyNow: boolean) => {
        setHasBeenAddToCartClicked(true);
        if (hasBeenAddToCartClicked) return;
        if (props.product?.minOrderQuantity > quantity) return;
        addToCart(product, quantity, image).then(() => {
            setQuantity(1);
            props.onCartItemsChange();
            displayAddToCartConfirmation();
            localStorage.setItem("recentlyAddedProductId", product.id);
            setHasBeenAddToCartClicked(false);
            if (buyNow) {
                navigate('/cart')
            }
        })
    };

    const currency = useSelector((state: RootState) => state.currency);
    const [quantity, setQuantity] = useState<number>(0);
    const [image, setImage] = useState<string>();
    const { t, i18n } = useTranslation('translation');
    const [showAddToCartConfimration, setShowAddToCartConfimration] = useState<boolean>(false);
    const productsIdsWithPickupPoints = useSelector((state: RootState) => state.productsIdsWithPickupPoints);
    const [pickupPoints, setPickupPoints] = useState<PickupPoint[] | undefined>([]);


    const handleMinus = (value: number): void => {
        if (quantity > value) {
            setQuantity(quantity - value);
        }
        else {
            setQuantity(0);
        }
    };

    const handlePlus = (value: number): void => {
        if (quantity < props.product?.numberOfItems) {
            setQuantity(quantity + value);
        }
        else {
            setQuantity(props.product?.numberOfItems)
        }
    };

    const checkDefaultQunatity = (): void => {
        props.product?.numberOfItems > 0 && setQuantity(1);
    }

    const getProductImage = () => {
        if (props.product && props.product.id && props.product.imageIds.length > 0) {
            getDimensionedImage(props.product.imageIds[0], PRODUCT_CARD_WIDTH, PRODUCT_CARD_HEIGHT).then((response) => {
                if (response) {
                    setImage(response)
                }
            })
        }
    }

    useEffect(() => {
        getProductImage();
        checkDefaultQunatity();
    }, []);

    const handleProductClick = () => {
        navigate(`/product-details/${props.product.id}/${props.product.parentProductId}${props.producerId ? `/${props.producerId}` : ''}`);
        if (props.otherProductComponent) {
            window.location.reload();
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    const navigate = useNavigate();

    function AddToCartConfirmation() {
        return (
            <div className={styles.add_to_cart_confirmation}>
                <CheckIcon fontSize='small' className={styles.check_icon} />
                <span>{t("productCard.addToCardConfirmation")}</span>
            </div>
        )
    }

    const displayAddToCartConfirmation = (): void => {
        setShowAddToCartConfimration(true);
        setTimeout(() => {
            setShowAddToCartConfimration(false);
        }, 4000);
    }

    useEffect(() => {
        const active: PickupPoint[] | undefined = productsIdsWithPickupPoints?.find((obj: any) => obj.productId === props.product?.id)?.pickupPoints
        setPickupPoints(active);
    }, [productsIdsWithPickupPoints]);

    const handleDoubleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = e.target.value;
        inputValue = inputValue.replace(",", ".");

        if (!isNaN(parseFloat(inputValue)) && props.product?.numberOfItems !== undefined) {
            let parsedValue = parseFloat(inputValue);

            if (parsedValue > props.product?.numberOfItems) {
                parsedValue = props.product?.numberOfItems;
            } else if (parsedValue < 0) {
                parsedValue = 0;
            }

            const fixedValue = parseFloat(parsedValue.toFixed(2));
            setQuantity(fixedValue);
        }
    };

    const handleIntInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = e.target.value;
        inputValue = inputValue.replace(/[^0-9]/g, '');

        if (inputValue !== '') {
            if (inputValue > props.product?.numberOfItems) {
                setQuantity(props.product?.numberOfItems);
            }
            else {
                setQuantity(Number(inputValue));
            }
        } else {
            setQuantity(0);
        }
    };

    const handleContractorRedirect = () => {
        navigate("/producer-details-view/" + props.product?.contractorId);
    }

    return (
        <div className={styles.card_container}>
            <div className={styles.image_icons_container}>
                {image ? (
                    <img className={styles.product_image}
                        src={image}
                        alt={props.product.fileName}
                        onClick={() => { handleProductClick() }} />
                ) : (
                    <div className={styles.image_placeholder}></div>
                )}
            </div>
            <div className={styles.product_name} onClick={handleProductClick}>{props.product.name}</div>
            <div className={styles.producer} onClick={handleContractorRedirect}>
                <p className={styles.producer_strong}>{t("productCard.producer")}:  </p>
                <p className={styles.producer_name}>
                    {
                        props.product?.producerName
                    }
                </p>
            </div>
            <div className={styles.price_and_amount_container}>
                {!showAddToCartConfimration &&
                    <div className={styles.amount_wrapper}>
                        <button
                            onClick={() => handleMinus(props.product?.isQuantityNaturalNumber ? 1 : 0.5)}
                            className={((props.product?.numberOfItems as number > 0) && (quantity !== 0)) ? styles.minus_button : `${styles.minus_button} ${styles.inactive}`}
                            disabled={props.product?.numberOfItems as number <= 0 || (quantity === 0)}
                        >
                            <p>-</p>
                        </button>
                        <input
                            type={props.product?.isQuantityNaturalNumber ? 'text' : 'number'}
                            className={styles.amount}
                            value={String(quantity)}
                            onChange={props.product?.isQuantityNaturalNumber ? handleIntInputChange : handleDoubleInputChange}
                            min={0}
                            step="any"
                        />
                        <div>
                            {props.product?.saleUnitName}
                        </div>
                        <button
                            onClick={() => handlePlus(props.product?.isQuantityNaturalNumber ? 1 : 0.5)}
                            className={((props.product?.numberOfItems as number > 0) && (quantity < (props.product?.numberOfItems as number))) ? styles.plus_button : `${styles.plus_button} ${styles.inactive}`}
                            disabled={props.product?.numberOfItems as number <= 0 || (quantity === (props.product?.numberOfItems as number))}
                        >
                            <p>+</p>
                        </button>
                    </div>
                }
                <div className={styles.price}>
                    <p>{props.product?.price ? currency + " " + calculateBrutto(props.product?.price, props?.product.vat) : currency + " 0.00"}</p>
                </div>
            </div>
            {
                (!showAddToCartConfimration) &&
                <button
                    disabled={props.product?.numberOfItems as number < props.product?.minOrderQuantity ||
                        quantity < props.product?.minOrderQuantity}
                    className={props.product?.numberOfItems as number >= props.product?.minOrderQuantity &&
                        quantity >= props.product?.minOrderQuantity ?
                        styles.add_to_cart :
                        `${styles.add_to_cart} ${styles.inactive}`}
                    onClick={() => placeInCart(props.product, quantity, image as unknown as ProductImage, false)}>
                    <p>+ {t("productCard.addToCart")}</p>
                </button>
            }
            {
                showAddToCartConfimration &&
                <AddToCartConfirmation />
            }
            <button
                className={props.product?.numberOfItems as number >= props.product?.minOrderQuantity &&
                    quantity >= props.product?.minOrderQuantity
                    ? styles.buy_button : `${styles.buy_button} ${styles.inactive}`}
                onClick={() => { placeInCart(props.product, quantity, image as unknown as ProductImage, true); }}
                disabled={props.product?.numberOfItems as number < props.product?.minOrderQuantity || quantity < props.product?.minOrderQuantity}
            >
                <img src={cartIcon} alt={"Cart"} />
                <p>{t("productCard.buyNow")}</p>
            </button>
            <div className={styles.pickup_points_list}>
                <p className={styles.availableIn} >{t("pickupPointsFilters.avaliableIn")}</p>
                <div className={styles.availableInContainer} >
                    <PickupPointList pickupPoints={props.product} />
                </div>
            </div>
            <div className={styles.availability}>
                <p>{t("productCard.available")}:{" "}
                    <span>
                        {props.product?.numberOfItems % 1 === 0 ?
                            props.product?.numberOfItems :
                            props.product?.numberOfItems.toFixed(2)
                        } {" "}
                        {props.product?.saleUnitName}
                    </span>
                </p>
                <p>{t("productCard.minOrderQuantity")}: <span>{props.product?.minOrderQuantity}{" "}
                    {props.product?.saleUnitName}</span></p>
            </div>

        </div >
    )
}
