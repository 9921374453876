import { t } from "i18next";
import styles from "./SelectPickupPoint.module.scss";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Typography } from '@mui/material';
import { PickupPoint } from "../../../shared/map/models";
import { getPickupPoints } from "../../../shared/map/services";
import { PluginStore } from "react-pluggable";
import { PluginEvent } from "../../Extensions/pluginEvent";

interface SelectPickupPointProps {
    pluginStore: PluginStore;
}

export default function SelectPickupPointComponent({ pluginStore }: SelectPickupPointProps) {
    const defaultValue = localStorage.getItem("pickupPointsFilter") ? localStorage.getItem("pickupPointsFilter")?.toString() : "any";
    const disabled = false;
    const showDefaultOption = false;
    const [selectedValue, setSelectedValue] = useState(defaultValue);
    const DEFAULT_OPTION_VALUE = "any";
    const { t, i18n } = useTranslation('translation');
    const [pickupPoints, setPickupPoints] = useState<PickupPoint[]>([]);

    useEffect(() => {
        getPickupPoints().then((response) => {
            if (response) {
                setPickupPoints(response.data);
            }
        });
    }, []);

    const handleChange = (event: SelectChangeEvent<string>) => {
        const id = event.target.value;
        setSelectedValue(event.target.value);
        if (id) {
            pluginStore.dispatchEvent(new PluginEvent("SelectedPickupPointChanged", { id: id }));
            localStorage.setItem('pickupPointsFilter', id as string);
            return;
        }
    };

    return (
        <div>
            <div className={styles.pickup_points_container}>
                <div className={styles.label}>{t("sellingSessions.label")}</div>
                <div className={styles.select_wrapper}>
                    <Box className={styles.container}>
                        <FormControl disabled={disabled}>
                            <Select
                                value={selectedValue as string}
                                onChange={handleChange}
                                className={styles.select}
                                renderValue={(value) =>
                                    value === DEFAULT_OPTION_VALUE
                                        ? t("pickupPointsFilters.any")
                                        : pickupPoints.find((pickupPoint: PickupPoint) => pickupPoint.id == value)?.name
                                }
                            >
                                {showDefaultOption && (
                                    <MenuItem key="default" value={DEFAULT_OPTION_VALUE} className={styles.menuItem}>
                                        <Typography variant="subtitle1">{t('pickupPointsFilters.any')}</Typography>
                                    </MenuItem>
                                )}
                                {Array.isArray(pickupPoints) && pickupPoints.map((pickupPoint: PickupPoint) => (
                                    <MenuItem key={pickupPoint?.id} value={pickupPoint?.id} className={styles.menuItem}>
                                        <div>
                                            <Typography variant="subtitle1">{pickupPoint?.name}</Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                {pickupPoint?.address?.streetName + " " + pickupPoint?.address?.buildingNumber}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                {pickupPoint?.address?.postalCode + " " + pickupPoint?.address?.city}
                                            </Typography>
                                        </div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </div>
            </div>
        </div >
    );
}