import { IPlugin, PluginStore } from "react-pluggable";
import SelectPickupPoint from "./components/SelectPickupPointPlugin/SelectPickupPoint";

class SelectPickupPointPlugin implements IPlugin {

    pluginStore!: PluginStore;

    getPluginName(): string {
        return "SelectPickupPoint@1.0.0"
    }
    getDependencies(): string[] {
        return [];
    }
    init(pluginStore: PluginStore): void {
        this.pluginStore = pluginStore;
    }
    activate(): void {
        this.pluginStore.executeFunction("Renderer.add", "page:selectPickupPoint", () => (
            <SelectPickupPoint pluginStore={this.pluginStore} />
        ));
        this.pluginStore.addEventListener("SelectedPickupPointChanged", () => { });
    }
    deactivate(): void {
        this.pluginStore.removeEventListener("SelectedPickupPointChanged", () => { });
    }
}

export default SelectPickupPointPlugin;