import React, { Component, useEffect, useState } from 'react';
import styles from './ProductsImageCarousel.module.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as imagesService from './services/image.service';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { Image } from './models';
import { decodeImageService } from '../../../../shared/services';
import { Carousel } from 'react-bootstrap';
import './arrows-styles.scss';


function getImages() {
    return imagesService.getSliderImages();
}

export default function ProductsImageCarousel() {
    const { t, i18n } = useTranslation('translation');
    const [images, setImages] = useState<Image[]>();
    const banner = require("../../../../assets/icons/banner.svg").default;

    useEffect(() => {
        getImages().then((response: any) => {
            const filteredImages = response?.data.filter((image: any) => image.isActive === true);
            setImages(filteredImages);
        });
    }, []);

    return (
        <div className={styles.product_carousel_container}>
            <Carousel className={styles.carousel_wrapper} interval={5000} controls={true}>
                {images && images?.map((image: Image, index) => (
                    <Carousel.Item key={index} className={styles.image_item} >
                        <a className={styles.link_wrapper} href={image.url}>
                            <img src={decodeImageService(image.content, image.name)} alt={image.name} />
                            <div className={styles.content_wrapper}>
                                {/* <div className={styles.carousel_item_content}>
                                    {image.name == "BANER_2.jpg" ?
                                        <div>
                                            <p className={styles.content_top}>{t("carousel.buyProducts.contentTop")}</p>
                                            <p className={styles.content_middle}>{parse(t('carousel.buyProducts.contentMiddle'))}</p>
                                            <p className={styles.content_bottom}>{parse(t("carousel.buyProducts.contentBottom"))}</p>
                                            <button>{t("carousel.buyProducts.buyNow")}</button>
                                        </div> :
                                        <div>
                                            <p className={styles.support_content_top}>{t("carousel.support.contentTop")}</p>
                                            <p className={styles.support_content_middle}>{parse(t('carousel.support.contentMiddle'))}</p>
                                            <p className={styles.support_content_bottom}>{parse(t("carousel.support.contentBottom"))}</p>
                                            <button className={styles.support_buy_now_button}>{t("carousel.support.buyNow")}</button>
                                        </div>
                                    }
                                </div> */}
                                {/* <div className={styles.banner}>
                                    <img src={banner} alt={"Banner"} />
                                </div> */}
                            </div>
                        </a>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    )
}


