import { useTranslation } from 'react-i18next'
import { decodeImageService } from '../../shared/services'
import styles from './ProducerCard.module.scss'
import { useNavigate } from 'react-router-dom';
import { ProductImage } from '../../shared/product-card/models/product-image.model';
import { useEffect, useState } from 'react';
import { getDimensionedImage, getImageById } from '../../shared/services/get-attachments.service';
import { AttachmentFile } from '../../shared/models/attachment-file.model';
import { PRODUCER_CARD_WIDTH, PRODUCER_CARD_HEIGHT, EMPTY_GUID } from '../../shared/constants/images-resolution.constants';

export default function ProducerCard(props: any) {

    const { i18n, t } = useTranslation("translation");
    const navigate = useNavigate();
    const [image, setImage] = useState<string>();
    const handleProductClick = () => {
        navigate("/producer-details-view/" + props.producer?.id);
    }

    useEffect(() => {
        if (props.producer.fileId !== null && props.producer.fileId !== undefined && props.producer.fileId !== EMPTY_GUID) {
            getDimensionedImage(props.producer.fileId, PRODUCER_CARD_WIDTH, PRODUCER_CARD_HEIGHT).then((response) => {
                if (response) {
                    setImage(response)
                }
            })
        }
    }, [props.producer])

    return (
        <div className={styles.card_container}>
            <div className={styles.image_icons_container}>
                {image ? (
                    <img
                        src={image}
                        alt={props.producer.imageName}
                        onClick={handleProductClick}
                        className={styles.producer_image}
                    />
                ) : (
                    <div className={styles.image_placeholder} onClick={handleProductClick}></div>
                )}
            </div>

            <div className={styles.producer_name_container}>
                <h6 onClick={handleProductClick}>{props.producer?.name}</h6>
            </div>
            <button onClick={handleProductClick} className={styles.more_button}>{t("producer.card.more")}</button>
        </div>
    )
}
