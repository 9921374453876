import React, { useEffect, useState, useRef } from 'react';
import parse from 'html-react-parser';
import { getCmsByLanguageAndCode } from './services';
import styles from './CmsContainer.module.scss';
import { usePluginStore } from 'react-pluggable';

function getCms(code: string, languageCode: string): Promise<any> {
    return getCmsByLanguageAndCode(code, languageCode);
}

export default function CmsContainer(props: { code: string; forwardedRef?: React.Ref<HTMLDivElement> }) {
    const [cmsObject, setCmsObject] = useState<string | undefined>();
    const pluginStore: any = usePluginStore();
    let Renderer = pluginStore.executeFunction("Renderer.getRendererComponent");
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const savedLanguageCode = localStorage.getItem('lang');

        if (savedLanguageCode) {
            getCms(props.code, savedLanguageCode).then((response: any) => {
                setCmsObject(response.data);
            });
        }
    }, [props.code]);

    useEffect(() => {
        if (props.forwardedRef && containerRef.current) {
            if (typeof props.forwardedRef === 'function') {
                props.forwardedRef(containerRef.current);
            }
        }
    }, [props.forwardedRef]);

    return (
        <div >
            <Renderer placement={"page:cms"} />
            <div ref={containerRef} className={styles.main_container}>
                {parse(cmsObject ? cmsObject : "")}
            </div>
        </div>
    );
}
