import React, { useEffect, useRef, useState } from 'react';
import styles from './ProductOfADay.module.scss';
import { useTranslation } from 'react-i18next';
import ButtonArrowBack from '../../../../shared/ui-elements/button-with-arrow-back/ButtonArrowBack';
import ButtonArrowForward from '../../../../shared/ui-elements/button-with-arrow-forward/ButtonArrowForward';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Product } from '../../models';
import { getProductsOfADay } from './services/get-products-of-a-day.service';
import ProductCard from '../../../../shared/product-card/ProductCard';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation } from "swiper";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';

export default function ProductsOfADay(props: any) {

    const filters = useSelector((state: RootState) => state.filters);

    const [productsToDisplay, setProductsToDisplay] = useState<Product[]>([]);
    const [beginning, setBeginning] = useState<boolean>(true);
    const [end, setEnd] = useState<boolean>(false);

    const [t, i18] = useTranslation();

    const swiperRef = useRef<any>(null);

    useEffect(() => {
        getProductsOfADay(filters).then((response: any) => {
            const products = response?.data as Product[];
            setProductsToDisplay(products);
            const width = window.innerWidth;
            let slidesPerView = 1;
            if (width >= 1300) slidesPerView = Math.min(4, products?.length);
            else if (width >= 1024) slidesPerView = Math.min(3, products?.length);
            else if (width >= 768) slidesPerView = Math.min(2, products?.length);

            if (products?.length <= slidesPerView) {
                setEnd(true);
            }
        })
    }, [filters]);

    const handleCartItemsChange = (): void => {
        props.onCartItemsChange();
    }


    return (
        <div className={styles.main_container}>
            <div className={styles.upper_part}>
                <div className={styles.centered}>
                    <p className={styles.title}>{t("productsOfADay.title")}</p>
                </div>
                <div className={styles.right}>
                    <ButtonArrowBack handleClick={() => swiperRef?.current?.swiper?.slidePrev()} isClickable={!beginning} />
                    <ButtonArrowForward handleClick={() => swiperRef?.current?.swiper?.slideNext()} isClickable={!end} />
                </div>
            </div>
            <div className={styles.items}>
                {
                    <Swiper ref={swiperRef}
                        key={productsToDisplay?.length}
                        slidesPerView={1} //Default value, then it will change on breakpoints
                        modules={[Navigation]}
                        className={styles.mySwiper}
                        spaceBetween={15}
                        onSlideChange={() => { setBeginning(swiperRef.current?.swiper?.isBeginning); setEnd(swiperRef.current?.swiper?.isEnd); }}
                        breakpoints={{
                            640: {
                                slidesPerView: Math.min(productsToDisplay?.length, 1),
                            },
                            768: {
                                slidesPerView: Math.min(productsToDisplay?.length, 2),
                            },
                            1024: {
                                slidesPerView: Math.min(productsToDisplay?.length, 3),
                            },
                            1300: {
                                slidesPerView: Math.min(productsToDisplay?.length, 4),
                            },
                        }}
                        focusableElements='div, ul, li, input, select, option, textarea, button, video, label'
                    >
                        {productsToDisplay && productsToDisplay?.map((product: Product, index: number) =>
                            <SwiperSlide key={index} className={styles.swiper_slide}>
                                <ProductCard key={product.id} product={product} onCartItemsChange={handleCartItemsChange}></ProductCard>
                            </SwiperSlide>
                        )}
                    </Swiper>
                }
            </div>
        </div>
    )
}
