import axios from 'axios';
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ProductCard from '../../../../shared/product-card/ProductCard';
import styles from './ProductsCatalogue.module.scss';
import { Product } from '../../models';
import Paginator from '../../../../shared/paginator/Paginator';
import { Category } from '../products-categories/models/Category.model';
import * as productDetailsService from '../../../product-details-view/services';
import uuid from 'react-uuid';
import Search from '../search/Search';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { ProductPaginated } from '../products-searched-view/models';

export default function ProductsCatalogue(props: any) {

    const filters = useSelector((state: RootState) => state.filters);

    const navigate = useNavigate();
    const [productObject, setProductObject] = useState<ProductPaginated>();

    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(20);
    const [categoryTree, setCategoryTree] = useState<Category[]>([]);
    const main_container_ref = useRef<any>(null);

    const getProductsByCategory = () => {
        return axios.get<ProductPaginated>("products/" + props.category?.id + "/pagin" + `?pageNumber=${currentPage + 1}` + `&pageSize=${perPage}` + `&pickupPointId=${filters.pickupPointId}`
            + `&productName=${filters.productName}` + `&categoryId=${props.category?.id}` + `&sortBy=${filters.sortBy}`);
    }

    useEffect(() => {
        getProductsByCategory().then((response) => {
            setProductObject(response.data);
            setCurrentPage(0);
        });
    }, [props.category, filters]);

    useEffect(() => {
        getProductsByCategory().then((response) => {
            setProductObject(response.data);
        });
    }, [currentPage, perPage]);

    const handlePageClick = (selectedPage: any) => {
        setCurrentPage(selectedPage.selected);
        main_container_ref?.current.scrollIntoView();
    };

    const handlePerPageChange = (event: any) => {
        setPerPage(parseInt(event.target.value));
        setCurrentPage(0);
    };

    const handleCartItemsChange = () => {
        props.onCartItemsChange();
    }

    const buildCategoryTreeAfterProductsFetched = (): void => {
        // productDetailsService.getAllCategories().then((response) => {
        //     const categories = response.data as Category[];
        //     const parentOfProducts = categories?.find(x => {
        //         return props.category?.parentProductId === x.id
        //     }) as Category;
        //     const tree: Category[] = [];
        //     tree.unshift(parentOfProducts);
        //     fillCategoryTree(categories, parentOfProducts, tree);
        // });
    };

    const fillCategoryTree = (categories: Category[], parent: Category, tree: Category[]) => {
        if (parent) {
            const addToTree = categories?.find(x => x.id === parent?.parentProductId) as Category;
            if (addToTree) {
                tree.unshift(addToTree);
            }
            fillCategoryTree(categories, addToTree, tree);
        }
        else {
            setCategoryTree(tree);
        }
    };

    useEffect(() => {
        buildCategoryTreeAfterProductsFetched();
    }, [props.category]);

    const handleSearchedClicked = () => {
        props.searchedClicked();
    }

    return (
        <div className={styles.main_container} ref={main_container_ref}>
            <div className={styles.categories}>
                <p onClick={() => navigate("/")}>Home</p>
                {
                    categoryTree && categoryTree?.map((cat, index) => (
                        cat?.name &&
                        <div key={uuid()} className={styles.category_list}>
                            <p onClick={() => { navigate("../catalogue/" + cat?.id, { replace: true }); window.scrollTo({ top: 750 }); }} className={styles.category_name}>/ {cat?.name}</p>
                        </div>
                    ))
                }
                <p>/</p>
                <p>{props.category?.name}</p>
            </div>

            <Search searchedClicked={handleSearchedClicked} />

            <h2 className={styles.cat_title}>
                {props.category?.name}
                <span className={styles.green_horizontal_line}></span>
            </h2>
            <div className={styles.cards_container}>
                {productObject?.items && productObject?.items?.map((product: Product) => (
                    <ProductCard key={product.id} product={product} onCartItemsChange={handleCartItemsChange}></ProductCard>
                ))}
            </div>
            <div className={styles.paginator_container}>
                <Paginator
                    perPage={perPage}
                    onPageClick={handlePageClick}
                    onPerPageChange={handlePerPageChange}
                    currentPage={currentPage ? currentPage : 0}
                    totalPages={productObject ? productObject?.totalPages as number : 0} />
            </div>
        </div>
    );
}
