import { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './ProductsCategories.module.scss';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Category } from './models/Category.model';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProductImage } from '../../../../shared/product-card/models/product-image.model';
import { decodeImageService } from '../../../../shared/services';
import { GetImageForCategory } from './services';
import { usePluginStore } from 'react-pluggable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { Filters } from '../../../../models/filters.model';
import { setFilters } from '../../../../store/actions';
import { useTranslation } from 'react-i18next';

export default function ProductsCategories(props: any) {

    const pluginStore: any = usePluginStore();
    let Renderer = pluginStore.executeFunction("Renderer.getRendererComponent");

    const filters = useSelector((state: RootState) => state.filters);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation('translation');

    const guidEmpty = "00000000-0000-0000-0000-000000000000";

    const [mainCategories, setMainCategories] = useState<Category[]>([]);
    const [selectedMainCategory, setSelectedMainCategory] = useState<string>();
    const [selectedCategoryWithChildren, setSelectedCategoryWithChildren] = useState<string[]>([]);
    const [selectedCategoryWithoutChildren, setSelectedCategoryWithoutChildren] = useState<string>();

    const [hoverElement, setHoverElement] = useState<string>("");

    useEffect(() => {
        fetchMainCategories();
    }, []);

    useEffect(() => {
        if (props.isSearched) {
            setSelectedMainCategory("");
            setSelectedCategoryWithChildren([]);
            setSelectedCategoryWithoutChildren("");
        }
    }, [props.isSearched]);

    const handleCategorySelect = (category: Category | undefined) => {
        // props.onSelectCategory(category);
        resetProductNameFilter(category?.id);
        navigate("../catalogue/" + category?.id, { replace: true });
    };

    const fetchMainCategories = async () => {
        try {
            const response = await axios.get(`categories/tree/${guidEmpty}`);
            const categories: Category[] = response?.data;
            const allProductsCategoryName = t('categories.allProducts');

            const allProductsCategory: Category = {
                id: "all",
                name: allProductsCategoryName,
                hasChildren: false,
                parentProductId: "",
                children: []
            };
            const categoriesWithAllProducts = [allProductsCategory, ...categories];
            setMainCategories(categoriesWithAllProducts);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchChildrenCategories = async (parentCategory: Category) => {
        if (parentCategory.hasChildren) {
            try {
                const response = await axios.get(`categories/tree/${parentCategory.id}`);
                const categories: Category[] = response?.data;
                parentCategory.children = categories;
                setMainCategories([...mainCategories]);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const resetProductNameFilter = (category: string | undefined): void => {
        const newFilters: Filters = {
            pickupPointId: filters.pickupPointId,
            categoryId: category ? category : null,
            productName: null
        };
        dispatch(setFilters(newFilters));
    };

    const handleSelectedMainCategoryChange = (category: Category): void => {
        if (selectedMainCategory === category.id) {
            setSelectedMainCategory("");
            setSelectedCategoryWithChildren([]);
            setSelectedCategoryWithoutChildren("");
            return;
        }
        setSelectedMainCategory(category.id);
    };

    const handleSelectedCategorysWithChildrenChange = (category: Category): void => {
        if (selectedCategoryWithChildren.includes(category.id)) {
            const index = selectedCategoryWithChildren.indexOf(category.id);
            selectedCategoryWithChildren.splice(index, 1);
            setSelectedCategoryWithoutChildren("");
            return;
        }
        const arrayCopy = [...selectedCategoryWithChildren];
        arrayCopy.push(category.id);
        setSelectedCategoryWithChildren(arrayCopy);
        setSelectedCategoryWithoutChildren("");
    };

    const handleSelectedCategorysWithoutChildrenChange = (category: Category): void => {
        if (selectedCategoryWithoutChildren === category.id) {
            setSelectedCategoryWithoutChildren("");
            return;
        }
        setSelectedCategoryWithoutChildren(category.id);
    };



    const renderTree = (category: Category) => {
        return (
            <ul className={styles.children_categories}>
                {
                    category.children.map((child) => (
                        <li className={styles.child_category} key={child.id}>
                            <p
                                className={(selectedCategoryWithChildren.includes(child.id) || selectedCategoryWithoutChildren === child.id) ? styles.green : undefined}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleCategorySelect(child);
                                    fetchChildrenCategories(child);
                                    child.hasChildren ?
                                        handleSelectedCategorysWithChildrenChange(child) :
                                        handleSelectedCategorysWithoutChildrenChange(child);
                                }}>
                                {child.name}
                            </p>
                            {(child?.children.length > 0 && selectedCategoryWithChildren.includes(child.id)) && renderTree(child)}
                        </li>
                    ))
                }
            </ul>
        );
    };
    return (
        <>
            <div className={styles.categories_container}>
                <Renderer placement={"categories:before"} />
                <ul className={styles.category_list}>
                    {mainCategories.map((category) => (
                        <li className={styles.category_item} key={category.id} onMouseOver={() => { setHoverElement(category.id); }} onClick={() => { handleSelectedMainCategoryChange(category); handleCategorySelect(category); }} onMouseLeave={() => { setHoverElement(""); }}>
                            {selectedMainCategory !== category.id &&
                                <>
                                    {
                                        category.id === hoverElement &&
                                        <span className={styles.line}></span>
                                    }
                                    <div className={hoverElement === category.id ? `${styles.content} ${styles.green}` : styles.content} onClick={() => {
                                        fetchChildrenCategories(category);
                                    }}>
                                        <div className={styles.category_item_content}>
                                            <div className={styles.category_item_image}>
                                                {/* {categoryImages[category.id] && <img src={categoryImages[category.id]} />} */}
                                            </div>
                                            <p >{category.name}</p>
                                            {
                                                category.hasChildren &&
                                                <div className={styles.arrow_container} onClick={() => {
                                                }}>
                                                    <ArrowForwardIosIcon fontSize='small' className={styles.arrow_expand} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>

                            }
                            {selectedMainCategory === category.id &&
                                <>
                                    <span className={styles.line}></span>

                                    <div className={styles.content_selected}>
                                        <div className={styles.category_item_content}>
                                            <div className={styles.category_item_image}>
                                                {/* {categoryImages[category.id] && <img src={categoryImages[category.id]} />} */}
                                            </div>
                                            <p>{category.name}</p>
                                            {
                                                category?.children &&
                                                <div className={styles.arrow_container}>
                                                    <ArrowForwardIosIcon fontSize='small' className={styles.arrow_collapse} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        category?.children.length > 0 &&
                                        renderTree(category)
                                    }
                                </>
                            }
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
}

