import { IPlugin, PluginStore } from "react-pluggable";
import SelectSharedPickupPoint from "./components/SelectSharedPickupPointPlugin/SelectSharedPickupPoint";

export default class SelectSharedPickupPointPlugin implements IPlugin {
    pluginStore!: PluginStore;
    getPluginName(): string {
        return "SelectSharedPickupPointPlugin@1.0.0";
    }
    getDependencies(): string[] {
        return []
    }
    init(pluginStore: PluginStore): void {
        this.pluginStore = pluginStore;
    }
    activate(): void {
        this.pluginStore.executeFunction("Renderer.add", "page:selectSharedPickupPoint", () => (
            <SelectSharedPickupPoint pluginStore={this.pluginStore} />
        ));
        this.pluginStore.addEventListener("SelectedPickupPointChanged", () => { });
    }
    deactivate(): void {
        this.pluginStore.removeEventListener("SelectedPickupPointChanged", () => { });
    }

}