import React, { useEffect, useRef, useState } from 'react';
import styles from './ProducersList.module.scss';
import { getProducers } from './services';
import { Producer } from './models';
import { useTranslation } from 'react-i18next';
import ButtonArrowBack from '../../../../shared/ui-elements/button-with-arrow-back/ButtonArrowBack';
import ButtonArrowForward from '../../../../shared/ui-elements/button-with-arrow-forward/ButtonArrowForward';
import { decodeImageService } from '../../../../shared/services';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation } from 'swiper';
import SmallProducerImage from './components/ProducerImage';

export default function ProducersList() {

    const [producersToDisplay, setProducersToDisplay] = useState<Producer[]>([]);
    const [beginning, setBeginning] = useState<boolean>(true);
    const [end, setEnd] = useState<boolean>(false);
    const swiperRef = useRef<any>(null);

    const [t, i18] = useTranslation();

    useEffect(() => {
        getProducers().then((response) => {
            const producers = response?.data as Producer[];
            setProducersToDisplay(producers);
            const width = window.innerWidth;
            let slidesPerView = 1;
            if (width >= 1680) slidesPerView = Math.min(4, producers?.length);
            else if (width >= 1024) slidesPerView = Math.min(3, producers?.length);
            else if (width >= 768) slidesPerView = Math.min(2, producers?.length);
            if (producers?.length <= slidesPerView) {
                setEnd(true);
            }
        })
    }, []);



    return (
        <div className={styles.main_container}>
            <div className={styles.upper_part}>
                <div className={styles.left}>
                    {t("producersList.producers")}
                </div>
                <div className={styles.right}>
                    <ButtonArrowBack handleClick={() => swiperRef?.current?.swiper?.slidePrev()} isClickable={!beginning} />
                    <ButtonArrowForward handleClick={() => swiperRef?.current?.swiper?.slideNext()} isClickable={!end} />
                </div>
            </div>
            <div className={styles.items}>
                {
                    <Swiper ref={swiperRef}
                        key={producersToDisplay?.length}
                        slidesPerView={1} //Default value, then it will change on breakpoints
                        modules={[Navigation]}
                        className={styles.mySwiper}
                        onSlideChange={() => { setBeginning(swiperRef.current?.swiper?.isBeginning); setEnd(swiperRef.current?.swiper?.isEnd); }}
                        breakpoints={{
                            640: {
                                slidesPerView: Math.min(producersToDisplay?.length, 1),
                            },
                            768: {
                                slidesPerView: Math.min(producersToDisplay?.length, 2),
                            },
                            1024: {
                                slidesPerView: Math.min(producersToDisplay?.length, 3),
                            },
                            1300: {
                                slidesPerView: Math.min(producersToDisplay?.length, 4),
                            },
                        }}
                    >
                        {producersToDisplay && producersToDisplay?.map((producer: Producer, index: number) =>
                            <SwiperSlide key={index}>
                                <div key={index} className={styles.item_wrapper}>
                                    {
                                        producer.fileId !== null && producer.fileId !== undefined &&
                                        <SmallProducerImage producerId={producer.id} fileId={producer.fileId} />
                                    }

                                    <div className={styles.name_container}>
                                        {producer?.name}
                                    </div>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                }
            </div>
        </div >
    )
}
